import styled from 'styled-components';

import { Spacing, Media } from '@lerna-monorepo/common/theme';

export const ProductBoxesContainer = styled.div`
  padding: 150px ${Spacing.DesktopWide};
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 2fr));
  grid-gap: 5px;
  ${Media.desktop`
    padding: 150px ${Spacing.Desktop};
  `}
  ${Media.tablet`
    padding: 100px ${Spacing.Tablet};
  `};
`;
