import React from 'react';

import { SmallImageHeader } from '@lerna-monorepo/common/components/smallImageHeader/smallImageHeader.component';
import { ProductBox } from '@lerna-monorepo/common/components/productBox/productBox.component';
import { ProductBoxData } from '@lerna-monorepo/common/components/productBox/productBox.types';
import { ButtonIconAdvertisingSection } from '@lerna-monorepo/common/components/buttonIconAdvertisingSection/buttonIconAdvertisingSection.component';

import { ProductsPageData } from './products.types';
import { ProductBoxesContainer } from './products.styled';

const ProductsPage: React.FC<ProductsPageData> = (
  pageData: ProductsPageData
) => {
  const { data } = pageData.pageContext;
  const { headerImage, productBoxSection, buttonIconAdvertisingSection } = data;

  const renderProductBoxes = () =>
    productBoxSection.map((box: ProductBoxData) => (
      <ProductBox
        key={box.header}
        header={box.header}
        subheader={box.subheader}
        description={box.description}
        photo={box.photo}
        link={box.link}
      />
    ));

  return (
    <>
      <SmallImageHeader image={headerImage} />
      <ProductBoxesContainer>{renderProductBoxes()}</ProductBoxesContainer>
      <ButtonIconAdvertisingSection
        icon={buttonIconAdvertisingSection.icon}
        textArray={buttonIconAdvertisingSection.textArray}
        buttonLabel={buttonIconAdvertisingSection.buttonLabel}
        buttonLink={buttonIconAdvertisingSection.buttonLink}
      />
    </>
  );
};

export default ProductsPage;
